import React, { useEffect, useState } from "react";
import axios from "axios";
import API_ENDPOINTS from "../../../service/api";
import coin from "../../../assets/ProfileCardimg/coin.jpeg";
import summary from "../../../assets/ProfileCardimg/summary.jpeg";
import rupee from "../../../assets/icons/rupee.svg";
import phonepe from "../../../assets/icons/phonepe.svg";
import sidevector from "../../../assets/sidevector.svg";
import { Link, useNavigate } from "react-router-dom";
import help from "../../../assets/help.svg";
import { useSelector } from "react-redux";

const PaymentAndOrderSummary = () => {
  const [billItem, setBillItem] = useState([]);
  const [gstPrice, setGstPrice] = useState(0);
  const [totalCalculated, setToatalCalculate] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [coupon, setCoupon] = useState("");
  const [promoMessage, setPromoMessage] = useState("");
  const [promoDiscount, setPromoDiscount] = useState(0);
  const [expandedIndex, setExpandedIndex] = useState(null);

  const gstRate = 0.18;
  const navigate = useNavigate();

  const UserData = useSelector((state) => state.authSlice.user);
  const TestData = useSelector((state) => state.testSlice);
  const PatientData = useSelector((state) => state.patientSlice.patientData);

  useEffect(() => {
    const fetchData = async () => {
      await getPriceOfTests(TestData.testsData);
    };

    fetchData();
  }, [TestData.testsData]);

  const getPriceOfTests = async (input) => {
    const url = `${API_ENDPOINTS.calculateTestPrice}?CenterID=${UserData.CentreID}`;
    const data = {
      testData: input,
    };
    try {
      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${UserData.token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        const priceData = response.data;
        console.log("----------------------------------------------------        Price Data:", priceData);

        const subtotal = priceData.totalCalculated;
        const gst = (subtotal * gstRate).toFixed(2);
        const total = (
          parseFloat(subtotal) +
          parseFloat(gst) -
          promoDiscount
        ).toFixed(2);

        setBillItem(priceData.result);
        setToatalCalculate(subtotal);
        setGstPrice(gst);
        setGrandTotal(total);
      } else {
        console.error("Error in fetching price data:", response.status);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const processPayment = async () => {
    const amount =
      (parseFloat(totalCalculated) + parseFloat(gstPrice) - promoDiscount) *
      100;
    const requestDataPayment = {
      PatientID: PatientData.PatientID,
      amount,
      paymentMode: "online",
      mobileNumber: PatientData.contactNumber,
      TechnicianID: UserData.LabTechnicianID,
      CenterID: UserData.CentreID,
      paymentBill: billItem,
    };

    try {
      const response = await axios.post(
        `${API_ENDPOINTS.payOnline}`,
        requestDataPayment,
        {
          headers: {
            Authorization: `Bearer ${UserData.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("________________________________________________ response.data",response.data);

      if (response.data?.payUrl?.url) {
        window.open(response.data.payUrl.url, "_blank");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const cashPayment = async () => {
    const amount =
      (parseFloat(totalCalculated) + parseFloat(gstPrice) - promoDiscount) *
      100;

    const requestDataPayment = {
      PatientID: PatientData.PatientID,
      amount,
      paymentMode: "cash",
      mobileNumber: PatientData.contactNumber,
      TechnicianID: UserData.LabTechnicianID,
      CenterID: UserData.CentreID,
      paymentBill: billItem,
    };

    try {
      const response = await axios.post(
        `${API_ENDPOINTS.payOnline}`,
        requestDataPayment,
        {
          headers: {
            Authorization: `Bearer ${UserData.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("______________________________________________________________ responce.data of cash",response.data);

      if (response.data) {
        console.log("______________________________________________________________ responce.data",response.data);
        navigate("/general-test");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const navigateTest = () => {
  //   navigate("/general-test");
  // };

  const checkTransaction = () => {
    console.log("Check transaction status");
  };

  const checkValidity = async () => {
    // console.log("_____________________ inside check");

    try {
      const response = await axios.patch(
        `${API_ENDPOINTS.promoCode}`,
        { code: coupon },
        {
          headers: {
            Authorization: `Bearer ${UserData.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200 && response.data.valid) {
        const discount = response.data.discount || 0;
        setPromoDiscount(discount);
        setPromoMessage("Promo code applied successfully!");
        const total = (
          parseFloat(totalCalculated) +
          parseFloat(gstPrice) -
          discount
        ).toFixed(2);
        setGrandTotal(total);
      } else {
        setPromoMessage("Invalid promo code.");
        setPromoDiscount(0);
      }
    } catch (error) {
      console.error("Error occurred:", error);
      setPromoMessage("Failed to validate promo code.");
    }
  };

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className="flex flex-col md:flex-row gap-6 p-8 bg-[#C7CEE5] min-h-[92vh] items-center justify-center">
      {/* Order Summary */}
      <div className="bg-[#845BB3] p-8 rounded-xl w-full max-w-md mx-auto text-white shadow-lg">
        <img
          src={summary}
          alt="lab"
          className="w-full h-40 object-cover rounded-xl mb-6"
        />
        <h2 className="text-center text-2xl font-bold mb-4">ORDER SUMMARY</h2>
        <ul className="mb-6">
          {billItem.map((test, index) => (
            <li key={index} className="mb-4">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleExpand(index)}
              >
                <span>{test.testFieldName}</span>
                <span>₹ {test.totalPrice}</span>
              </div>
              {expandedIndex === index && (
                <ul className="mt-2 ml-4 text-sm text-gray-200">
                  {test.totalSubfields.map((subfield, subIndex) => (
                    <li key={subIndex} className="flex justify-between mb-1">
                      <span>{subfield.fieldName}</span>
                      <span>₹ {subfield.fieldPrice}</span>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
        <hr />
        <div className="flex justify-between items-center mb-2">
          <span>Subtotal</span>
          <span>₹ {totalCalculated}</span>
        </div>
        <div className="flex justify-between items-center mb-2">
          <span>GST</span>
          <span>₹ {gstPrice}</span>
        </div>
        {promoDiscount > 0 && (
          <div className="flex justify-between items-center mb-2 text-green-400">
            <span>Promo Code Discount</span>
            <span>- ₹ {promoDiscount}</span>
          </div>
        )}
        <div className="flex justify-between items-center text-lg font-bold">
          <span>Total</span>
          <span>₹ {grandTotal}</span>
        </div>
        <div className="mt-4">
          <input
            type="text"
            placeholder="Enter Promo Code"
            value={coupon}
            onChange={(e) => setCoupon(e.target.value)}
            className="w-full p-3 rounded-lg text-black mb-2"
          />
          {promoMessage && (
            <p
              className={`text-sm mt-0 mb-3 ${
                promoDiscount > 0 ? "text-green-500" : "text-black"
              }`}
            >
              {promoMessage}
            </p>
          )}
          <button
            onClick={checkValidity}
            className="w-full py-3 bg-[#7d57e6] text-white rounded-lg hover:bg-[#5a3dbf]"
          >
            Apply Promo Code
          </button>
        </div>
      </div>

      {/* Payment Details */}
      <div className="bg-[#f3f1fc] z-10 p-8 rounded-xl w-full max-w-md mx-auto shadow-lg">
        <img
          src={coin}
          alt="coin"
          className="w-full h-40 object-cover rounded-xl mb-6"
        />
        <h2 className="text-center text-2xl font-bold text-[#7d57e6] mb-4">
          PAYMENT DETAILS
        </h2>
        <p className="text-center text-sm text-gray-600 mb-6">
          Choose your payment methods
        </p>
        <div className="flex justify-center gap-6 mb-6">
          <button
            className="text-white rounded-lg h-20 w-20 px-6 py-6 border hover:border-4 border-[#5a3dbf] font-semibold transition"
            onClick={cashPayment}
          >
            <img
              src={rupee}
              alt="coin"
              className="w-full object-cover rounded-md"
            />
          </button>
          <button
            className="bg-[#9372ec] rounded-lg h-20 w-20 px-4 py-4 border hover:border-4 border-[#5a3dbf] font-semibold transition"
            onClick={processPayment}
          >
            <img
              src={phonepe}
              alt="coin"
              className="w-full object-cover rounded-md"
            />
          </button>
        </div>
        <p className="text-center text-sm text-[#ff0000] mb-6">
          Your Payment Has Been Failed, Please Try Again - Money Deducted from
          your account? Contact Us.
        </p>
        <input
          type="text"
          placeholder="Enter Transaction ID"
          className="w-full border border-gray-300 rounded-xl p-3 mb-6 focus:outline-none focus:ring-2 focus:ring-[#7d57e6]"
        />
        <div className="flex justify-center">
          <button
            className="bg-[#7d57e6] text-white py-3 px-12 rounded-lg font-semibold hover:bg-[#5a3dbf] transition"
            onClick={checkTransaction}
          >
            Check Status
          </button>
        </div>
      </div>

      {/* Help Icon */}
      {/* <Link to="../faq" className="fixed z-10 bottom-8 right-28">
        <img src={help} alt="helpicon" className="w-10 lg:w-12" />
      </Link>
      <div className="absolute bottom-0 right-0 z-0 w-full lg:w-auto">
        <img src={sidevector} alt="sidevector" className="w-full lg:w-auto" />
      </div> */}
    </div>
  );
};

export default PaymentAndOrderSummary;
