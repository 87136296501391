const API_ENDPOINTS = {
  signUpApi: "https://api.superceuticals.in/technicians/login",
  addPatient: "https://api.superceuticals.in/patient/add-patient",
  getTestResults: "https://api.superceuticals.in/patient/get-patientTest/SC23F000005",
  addTestReport: "https://api.superceuticals.in/test/add-testResult",
  calculateTestPrice :"https://api.superceuticals.in/testPrice/get-total-price",
  payOnline:"https://api.superceuticals.in/payment/makePayment",
  promoCode :"https://api.superceuticals.in/promocode/validate",
  addReport :"https://api.superceuticals.in/report/add-report/",
  bodyCompostionApi: "https://localhost:3003/api/data",
  getFactor: "https://api.superceuticals.in/heightFactor",
  getTestResultsLocal: "https://api.superceuticals.in/test/getTestResult/",
};

export default API_ENDPOINTS;
