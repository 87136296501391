import React, { useRef, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import help from "../../assets/help.svg";
import axios from "axios";
import sidevector from "../../assets/sidevector.svg";
import download from "../../assets/downloadpdf.svg";
import share from "../../assets/sharepdf.svg";
// import whatsapp from "../../assets/whatsapp.svg";
import whatsapp from "../../assets/icon-whatsapp2.svg";
import PatientReport from "../../Components/PatientReport";
import ReactToPrint from "react-to-print";
import API_ENDPOINTS from "../../service/api";
import html2pdf from "html2pdf.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { commentsData } from "../../translate/testInstruction";
import { albumin, samplelipidprofile } from "../../translate/testSummery";

export default function ReportPage() {
  // Access patient and test data from state
  const [loading, setLoading] = useState(false);
  const patientDetails = useSelector((state) => state.patientSlice.patientData);
  const UserData = useSelector((state) => state.authSlice.user);
  const refBy = useSelector((state) => state.testSlice.referedBy);

  const location = useLocation();
  const fromGeneralPage = location.state?.fromGeneralPage || false;

  console.log("______________ patientDetails", patientDetails);
  const updatedTestData = useSelector((state) => state.testSlice.testsData);

  const testData = updatedTestData.map((item) => {
    const updatedFields = item.fields.flatMap(
      (arr) =>
        arr.subFields && arr.subFields.length > 0 ? arr.subFields : [arr] // Include subFields if they exist, otherwise include parent
    );
    return {
      ...item,
      fields: updatedFields,
    };
  });

  console.log("Updated Test Data with Conditional Flattening:", testData);

  // Now you can log the updated data
  // console.log("Updated Test Data:", testData);
  // console.log("Original Test Data:", testData);

  const testNames = [];
  commentsData.forEach((item) => {
    Object.keys(item).forEach((testName) => {
      const formattedTestName = testName
        .toLowerCase()
        .replace(/[^a-z0-9]/g, "");
      testNames.push(formattedTestName);
    });
  });
  // console.log("_testNames = ", testNames);
  const processedTestData = [];

  if (testData.length > 0) {
    const testFieldsCount = testData[0].fields.length;
    console.log("Number of fields in the first test:", testData[0]);

    const testName = [];
    const fieldName = [];

    // Iterate over testData

    for (let i = 0; i < testData.length; i++) {
      let item1 = testData[i];

      // Check if fields array exists and has length > 0
      console.log("63 ---item1", item1);
      if (Array.isArray(item1.fields) && item1.fields.length > 0) {
        console.log(item1.fields.length);
        for (let j = 0; j < item1.fields.length; j++) {
          const item2 = item1.fields[j];
          const cleanParentField = "urinecreatininemicroalbumin";
          const routineParentField = "routinepanel";
          const renalProfileParentField = "completerenalprofile";
          console.log("item2---", item2);
          if (
            item1.fields[j]?.parentField
              ?.replace(/[^a-z0-9]/gi, "")
              .toLowerCase() === cleanParentField
          ) {
            // Filter the fields directly
            const filteredFields = item1.fields.filter(
              (item) =>
                item?.parentField?.replace(/[^a-z0-9]/gi, "").toLowerCase() ===
                cleanParentField
            );

            // Shallow copy the original item and update the fields with filtered data
            const shallowCopyItem = {
              ...item1,
              fields: filteredFields,
            };

            console.log("Shallow Copy with Filtered Fields:", shallowCopyItem);

            // Push the shallow copy to processedTestData
            processedTestData.push(shallowCopyItem);

            // Now, remove the matching fields from the original item1 object
            item1.fields = item1.fields.filter(
              (item) =>
                item?.parentField?.replace(/[^a-z0-9]/gi, "").toLowerCase() !==
                cleanParentField
            );

            console.log(
              "Original item1 after removing matching fields:",
              item1
            );
            j--;

            const sampleurinecreatininemicroalbumin = {
              fields: [
                {
                  fieldName: "sampleurinecreatininemicroalbumin ",
                },
              ],
              testFieldName: "Kidney Marker",
            };

            processedTestData.push(sampleurinecreatininemicroalbumin);

            // Continue processing (if needed)
          } else if (
            item1.fields[j]?.parentField
              ?.replace(/[^a-z0-9]/gi, "")
              .toLowerCase() === routineParentField
          ) {
            // Filter the fields directly
            const filteredFields = item1.fields.filter(
              (item) =>
                item?.parentField?.replace(/[^a-z0-9]/gi, "").toLowerCase() ===
                routineParentField
            );

            // Shallow copy the original item and update the fields with filtered data
            const shallowCopyItem = {
              ...item1,
              fields: filteredFields,
            };

            console.log("Shallow Copy with Filtered Fields:", shallowCopyItem);

            // Push the shallow copy to processedTestData
            processedTestData.push(shallowCopyItem);

            // Now, remove the matching fields from the original item1 object
            item1.fields = item1.fields.filter(
              (item) =>
                item?.parentField?.replace(/[^a-z0-9]/gi, "").toLowerCase() !==
                routineParentField
            );

            console.log(
              "Original item1 after removing matching fields:",
              item1
            );
            j--;
            const sampleroutinepanel = {
              fields: [
                {
                  fieldName: "sampleroutinepanel",
                },
              ],
              testFieldName: "Kidney Markers",
            };
            processedTestData.push(sampleroutinepanel);
            // Continue processing (if needed)
          } else if (
            item1.fields[j]?.parentField
              ?.replace(/[^a-z0-9]/gi, "")
              .toLowerCase() === renalProfileParentField
          ) {
            // Filter the fields directly
            const filteredFields = item1.fields.filter(
              (item) =>
                item?.parentField?.replace(/[^a-z0-9]/gi, "").toLowerCase() ===
                renalProfileParentField
            );

            // Shallow copy the original item and update the fields with filtered data
            const shallowCopyItem = {
              ...item1,
              fields: filteredFields,
            };

            console.log("Shallow Copy with Filtered Fields:", shallowCopyItem);

            // Push the shallow copy to processedTestData
            processedTestData.push(shallowCopyItem);

            // Now, remove the matching fields from the original item1 object
            item1.fields = item1.fields.filter(
              (item) =>
                item?.parentField?.replace(/[^a-z0-9]/gi, "").toLowerCase() !==
                renalProfileParentField
            );

            console.log(
              "Original item1 after removing matching fields:",
              item1
            );
            j--;

            const sampleRenal = {
              fields: [
                {
                  fieldName: "sampleRenal",
                },
              ],
              testFieldName: "Kidney Markers",
            };
            processedTestData.push(sampleRenal);
            const sampleRenal2 = {
              fields: [
                {
                  fieldName: "sampleRenal2",
                },
              ],
              testFieldName: "Kidney Markers",
            };
            processedTestData.push(sampleRenal2);

            // Continue processing (if needed)
          } else if (item1.fields[j].secfieldName) {
            console.log("secFieldName");
            const tname = item2.secfieldName;
            // console.log("tname", tname);

            testName.push(tname);

            // Check if tname exists in testNamesArray
            if (testNames.includes(tname)) {
              let index = item1.fields.findIndex(
                (obj) =>
                  obj.fieldName.toLowerCase().replace(/[^a-z0-9]/g, "") ===
                  tname
              );

              const shallowCopyItem = { ...item1 };
              // console.log("84----shallowCopy", shallowCopyItem);

              const filteredFields = shallowCopyItem.fields.filter(
                (_, idx) => idx === index
              );
              processedTestData.push(shallowCopyItem);

              shallowCopyItem.fields = filteredFields;

              // console.log("92 --- processData");

              if (index !== -1) {
                // console.log(typeof item1);
                const updatedItem = {
                  ...item1,
                  fields: [
                    ...item1.fields.slice(0, index),
                    ...item1.fields.slice(index + 1),
                  ],
                };
                j--;
                item1 = updatedItem;
                // console.log("item1 =", item1);
                // console.log("Updated fields after removal:", updatedItem);
              }
            }
            // const item1Size = item1.fields?.length ?? 0;
            // if (item1Size <= 12 && item1Size > 0) {
            //   // processedTestData.push(item1);
            // }

            const fname = item2.fieldName.toLowerCase().replace(/\s+/g, "");
            fieldName.push(fname);
          } else {
            console.log("273", item1);
            const tname = item2.fieldName
              .toLowerCase()
              .replace(/[^a-z0-9]/g, "");
            console.log("no secFieldName", tname);
            testName.push(tname);

            if (testNames.includes(tname)) {
              let index = item1.fields.findIndex(
                (obj) =>
                  obj.fieldName.toLowerCase().replace(/[^a-z0-9]/g, "") ===
                  tname
              );

              const shallowCopyItem = { ...item1 };
              const filteredFields = shallowCopyItem.fields.filter(
                (_, idx) => idx === index
              );

              shallowCopyItem.fields = filteredFields;
              processedTestData.push(shallowCopyItem);

              // Map of test names to their sample objects
              const sampleMapping = {
                pct: {
                  fields: [{ fieldName: "samplePCT" }],
                  testFieldName: "Infection Marker",
                },
                // dengueigmigg: {
                //   fields: [{ fieldName: "sampledengueigmigg" }],
                //   testFieldName: "vectore",
                // },

                bloodpressure: {
                  fields: [{ fieldName: "samplebloodpressure" }],
                  testFieldName: "Body Analysis",
                },
                bilirubintotal: {
                  fields: [{ fieldName: "samplebilirubintotal" }],
                  testFieldName: "Liver Marker",
                },
                ggtp: {
                  fields: [{ fieldName: "smapleggtp" }],
                  testFieldName: "Liver Marker",
                },
                alp: {
                  fields: [{ fieldName: "samplealp" }],
                  testFieldName: "Liver Marker",
                },
                bilirubindirect: {
                  fields: [{ fieldName: "samplebilirubindirect" }],
                  testFieldName: "Liver Marker",
                },
                totalprotein: {
                  fields: [{ fieldName: "sampletotalprotein" }],
                  testFieldName: "Liver Marker",
                },
                albumin: {
                  fields: [{ fieldName: "samplealbumin" }],
                  testFieldName: "Liver Marker",
                },
                sgptalt: {
                  fields: [{ fieldName: "samplesgptalt" }],
                  testFieldName: "Liver Marker",
                },
                sgotast: {
                  fields: [{ fieldName: "samplesgoast" }],
                  testFieldName: "Liver Marker",
                },
                routinepanel: {
                  fields: [{ fieldName: "sampleroutinepanel" }],
                  testFieldName: "Kidney Marker",
                },
                urinealbumin: {
                  fields: [
                    {
                      fieldName: "sampleurinealbumin",
                      bioReferenceInterval: "-",
                    },
                  ],
                  testFieldName: "Kidney Marker",
                },
                betahcg: {
                  fields: [{ fieldName: "sample2betahcg" }],
                  testFieldName: "Hormonal",
                },

                potassium: {
                  fields: [{ fieldName: "samplepotassium" }],
                  testFieldName: "Kidney Marker",
                },
                hemoglobin: {
                  fields: [{ fieldName: "samplehemoglobin" }],
                  testFieldName: "Blood Marker",
                },
                uricacid: {
                  fields: [{ fieldName: "sampleuricacid" }],
                  testFieldName: "Kidney Marker",
                },
                sodium: {
                  fields: [{ fieldName: "samplesodium" }],
                  testFieldName: "Kidney Marker",
                },
                // totalprotein: {
                //   fields: [{ fieldName: "sampletotalprotein" }],
                //   testFieldName: "Kidney Marker",
                // },
                lhluteinizinghormone: {
                  fields: [{ fieldName: "samplelhluteinizinghormone" }],
                  testFieldName: "Hormonal",
                },
                chikungunyaigmigg: {
                  fields: [{ fieldName: "samplechikungunyaigmigg" }],
                  testFieldName: "vector borne",
                },

                totalt4thyroxine: {
                  fields: [{ fieldName: "sampletotalt4thyroxine" }],
                  testFieldName: "Hormonal",
                },
                ft4freethyroxine: {
                  fields: [{ fieldName: "sampleft4freethyroxine" }],
                  testFieldName: "Hormonal",
                },
                lipidprofile: {
                  fields: [{ fieldName: "samplelipidprofile" }],
                  testFieldName: "Cardiac",
                },
                filarialantigendetection: {
                  fields: [{ fieldName: "samplefilarialantigendetection" }],
                  testFieldName: "Elephantiasis Ag",
                },
                tsh: {
                  fields: [{ fieldName: "sampleTsh" }],
                  testFieldName: "Hormonal",
                },
                ntprobnp: {
                  fields: [{ fieldName: "samplentprobnp" }],
                  testFieldName: "Cardiac",
                },
                creatine: {
                  fields: [{ fieldName: "samplecreatine" }],
                  testFieldName: "Kidney Marker",
                },
              };

              // Add the sample object if tname exists in the mapping
              if (sampleMapping[tname]) {
                processedTestData.push(sampleMapping[tname]);
              }
              console.log("348", item1);
              if (tname === "lhluteinizinghormone") {
                const sample2lhluteinizinghormone = {
                  fields: [
                    {
                      fieldName: "sample2lhluteinizinghormone",
                    },
                  ],
                  testFieldName: "Hormonal",
                };
                console.log("396", item1.fields[0].fieldName);
                processedTestData.push(sample2lhluteinizinghormone);
              }

              // Remove the matched field from item1 if index is valid
              if (index !== -1) {
                const updatedItem = {
                  ...item1,
                  fields: [
                    ...item1.fields.slice(0, index),
                    ...item1.fields.slice(index + 1),
                  ],
                };
                j--;
                item1 = updatedItem;
                console.log("item1 =", item1);
                console.log("Updated fields after removal:", updatedItem);
              }
            }

            const fname = item2.fieldName.toLowerCase().replace(/\s+/g, "");

            fieldName.push(fname);
            console.log("366", fname);
          }
        }

        const item1Size = item1.fields?.length ?? 0;
        console.log("372", item1);

        if (item1Size <= 12 && item1Size > 0) {
          processedTestData.push(item1);
          console.log("405", item1.fields[0].fieldName);
          if (item1.fields[0].fieldName === "Creatinine") {
            const samplecreatinine = {
              fields: [
                {
                  fieldName: "samplecreatinine",
                },
              ],
              testFieldName: "Kidney Markers",
            };
            processedTestData.push(samplecreatinine);
          }
          if (item1.fields[0].fieldName === "Chikungunya IgM/IgG") {
            const samplecreatinine = {
              fields: [
                {
                  fieldName: "samplecreatinine",
                },
              ],
              testFieldName: "Kidney Markers",
            };
            processedTestData.push(samplecreatinine);
          }

          console.log("494", item1);

          if (item1.fields[0].fieldName === "Height") {
            const samplegeneral = {
              fields: [
                {
                  fieldName: "samplegeneral",
                },
              ],
              testFieldName: "Kidney Markers",
            };
            processedTestData.push(samplegeneral);
          }
          const sampleMapping = {
            "Lipid Profile": {
              fields: [{ fieldName: "samplelipidprofile" }],
              testFieldName: "Lipid Profile",
            },
            "Syphillis/HIV Combo": {
              fields: [{ fieldName: "samplesyphillishivcombo" }],
              testFieldName: "Syphillis/HIV Combo",
            },
            "Typhoid IgM/ IgG": {
              fields: [{ fieldName: "sampletyphoidigmigg" }],
              testFieldName: "Typhoid IgM/ IgG",
            },
            "Chikungunya IgM/ IgG": {
              fields: [{ fieldName: "samplechikungunyaigmigg" }],
              testFieldName: "Typhoid IgM/ IgG",
            },

            "Cholera Ag": {
              fields: [{ fieldName: "samplecholeraag" }],
              testFieldName: "Cholera Ag",
            },
            "Leptospira IgM/ IgG": {
              fields: [{ fieldName: "sampleleptospiraigmigg" }],
              testFieldName: "Leptospira IgM/ IgG",
            },
            "HIV Antibody": {
              fields: [{ fieldName: "samplehivantibody" }],
              testFieldName: "HIV Antibody",
            },
            "H. Pylori FIA": {
              fields: [{ fieldName: "samplehpyloriantigen" }],
              testFieldName: "H. Pylori FIA",
            },
            Urea: {
              fields: [{ fieldName: "sampleurea" }],
              testFieldName: "Urea",
            },
            "Elephantiasis Ag": {
              fields: [{ fieldName: "sampleelephantiasisag" }],
              testFieldName: "Elephantiasis Ag",
            },
            "Maleria pf PAN Ag": {
              fields: [{ fieldName: "samplemaleriapfpanag" }],
              testFieldName: "Maleria pf PAN Ag",
            },
            "Scrub Typhus IgM/ IgG": {
              fields: [{ fieldName: "samplescrubtyphusigmigg" }],
              testFieldName: "Scrub Typhus IgM/ IgG",
            },
            "Maleria Pf/Pv Ag": {
              fields: [{ fieldName: "samplemaleriapfpvag" }],
              testFieldName: "Maleria Pf/Pv Ag",
            },
            "Syphillis Antibody": {
              fields: [{ fieldName: "samplesyphillisantibody" }],
              testFieldName: "Syphillis Antibody",
            },
            "Dengue Duo - Ag + Ab": {
              fields: [{ fieldName: "sampledengueduoagab" }],
              testFieldName: "Dengue Duo - Ag + Ab",
            },

            HbA1c: {
              fields: [{ fieldName: "samplehba1c" }],
              testFieldName: "HbA1c",
            },
            "Complete Liver Function Tests": {
              fields: [{ fieldName: "samplecompleteliverfunctiontests" }],
              testFieldName: "Complete Liver Function Tests",
            },
          };

          // Check if the testFieldName of item1 matches any in the mapping
          if (sampleMapping[item1.testFieldName]) {
            console.log(`----${item1.testFieldName}`);
            processedTestData.push(sampleMapping[item1.testFieldName]);
          }
        } else {
          const item = item1;
          const fieldsSize = item1Size;
          if (fieldsSize > 12) {
            let total = item1.fields.length;
            let count = 0;
            for (let k = 0; k < total; k++) {
              if (item1.fields[k].parentField === "Complete Renal Profile") {
                count += 1;
              }
            }

            for (let k = 0; k < total; k++) {
              if (
                item1.fields[k].parentField === "Complete Liver Function Tests"
              ) {
                count += 1;
              }
            }
            console.log(count, "&&", total);
            if (count === total) {
              processedTestData.push(item1);
            } else {
              // Split the fields array into two halves
              const halfSize = Math.ceil(fieldsSize / 2);
              const firstHalf = item.fields.slice(0, halfSize);
              const secondHalf = item.fields.slice(halfSize);

              // Create two new objects with split fields
              const firstPart = { ...item, fields: firstHalf };
              const secondPart = { ...item, fields: secondHalf };

              // Add the new objects to the processedTestData array
              processedTestData.push(firstPart, secondPart);
              // if (item1.testFieldName === "Complete Blood Count") {
              //   const samplecompletebloodcount = {
              //     fields: [
              //       {
              //         fieldName: "samplecompletebloodcount",
              //         bioReferenceInterval: "-",
              //       },
              //     ],
              //     testFieldName: "Complete Blood Count",
              //   };

              //   processedTestData.push(samplecompletebloodcount);
              // }
            }
          }
        }
      }
    }

    console.log("Final fieldName array:", fieldName);
    console.log("New Test Data Array:", processedTestData);
  } else {
    console.log("No test data available.");
  }

  // Split large field arrays

  // for (let i = 0; i < testData.length; i++) {
  //   const item = testData[i];
  //   const fieldsSize = item.fields?.length ?? 0;

  //   if (fieldsSize > 10) {
  //     // Split the fields array into two halves
  //     const halfSize = Math.ceil(fieldsSize / 2);
  //     const firstHalf = item.fields.slice(0, halfSize);
  //     const secondHalf = item.fields.slice(halfSize);

  //     // Create two new objects with split fields
  //     const firstPart = { ...item, fields: firstHalf };
  //     const secondPart = { ...item, fields: secondHalf };

  //     // Add the new objects to the processedTestData array
  //     processedTestData.push(firstPart, secondPart);
  //   }
  // }

  // Ref to the specific part (Patient Report) you want to print
  const patientReportRef = useRef();

  // Handle Share functionality (only share the report section as PDF)
  const handleShare = () => {
    const element = patientReportRef.current;

    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5],
      filename: "report.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: {
        scale: 4,
      },
      jsPDF: {
        unit: "in",
        format: "a4",
        orientation: "portrait",
      },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    };

    html2pdf()
      .set(opt)
      .from(element)
      .outputPdf("blob") // Generate PDF as Blob
      .then((pdfBlob) => {
        // Create a File object from the Blob
        const filesArray = [
          new File([pdfBlob], "report.pdf", {
            type: "application/pdf",
            lastModified: new Date().getTime(),
          }),
        ];

        // Check if the browser supports sharing files
        if (navigator.canShare && navigator.canShare({ files: filesArray })) {
          navigator
            .share({
              files: filesArray,
              title: "Patient Report",
              text: "Here is the patient report.",
            })
            .then(() => console.log("Share was successful."))
            .catch((error) => console.log("Sharing failed", error));
        } else {
          alert("Your device does not support sharing files.");
        }
      })
      .catch((error) => console.log("Error generating PDF", error));
  };

  const handlePdfUpload = () => {
    const element = patientReportRef.current;
  
    // Get the actual dimensions of the content
    const contentWidth = element.offsetWidth;
    const contentHeight = element.offsetHeight;
  
    const opt = {
      margin: [0.0, 0.0, 0.0, 0.0],
      filename: "report.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: {
        scale: 2, // Adjust scale for better quality
        width: contentWidth,
        height: contentHeight,
      },
      jsPDF: {
        unit: "px", // Use pixels for more precise alignment
        format: [contentWidth, contentHeight], // Set format based on content size
        orientation: "portrait",
      },
    };
  
    html2pdf()
      .set(opt)
      .from(element)
      .outputPdf("blob")
      .then((pdfBlob) => {
        const reader = new FileReader();
        reader.readAsDataURL(pdfBlob);
        reader.onloadend = () => {
          const base64String = reader.result.replace(
            "data:application/pdf;base64,",
            ""
          );
          const postData = {
            PatientName: patientDetails.Name,
            pdf: base64String,
          };
          setLoading(true);
          const reportId = patientDetails.PatientID;
          axios
            .post(`${API_ENDPOINTS.addReport}${reportId}`, postData, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${UserData.token}`,
              },
            })
            .then((response) => {
              const data = response.data;
              if (data.error) {
                console.error("Error uploading report:", data.message);
                toast.error(data.message, { position: "top-center" });
                setLoading(false);
              } else {
                toast.success(data.message, { position: "top-center" });
                setLoading(false);
              }
            })
            .catch((error) => {
              toast.error(error.message, { position: "top-center" });
              setLoading(false);
            });
        };
      })
      .catch((error) => console.error("Error generating PDF", error));
  };
  
  const submitTestResult = async () => {
    if (!fromGeneralPage) {
      console.log(
        "Navigation is not from the general page. API will not be called."
      );
      return;
    }
    const url = "https://api.superceuticals.in/test/add-testReport";
    const body = {
      PatientID: patientDetails.PatientID,
      ReferredBy: refBy,
      TestData: processedTestData,
      TechnicianID: "TECH433853",
      CentreID: "CEN18781001",
      MachineID: "MC0002",
    };
    // processedTestData.forEach((test) => {
    //   test.fields.forEach((field) => {
    //     const cleanedFieldName = field.fieldName
    //       .replace(/[^a-zA-Z0-9]/g, "")
    //       .toLowerCase();
    //     console.log("769", field);
    //     body[cleanedFieldName] = field.value;
    //   });
    // });
    console.log("771", body);
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiVEVTVE5PVyIsIlBhdGllbnRJRCI6IlNDMjNGMDAwMDAyIiwiY29udGFjdE51bWJlciI6Ijg4MDU5OTEwNzkiLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE3Mjk1OTI1NDZ9.nxGwqh-dGY4fpdbHLeq7Cli8Hb8xMdnNfvmECkW-CQs",
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Test result submitted successfully:", result);
    } catch (error) {
      console.error("Error submitting test result:", error);
    }
  };
  console.log("Processed Test Data:", processedTestData);
  useEffect(() => {
    submitTestResult();
  }, []);

  return (
    <>
      {/* Printable content: PatientReport components */}
      <div className="bg-[#C7CEE5] justify-center items-center flex min-h-fit  py-10 px-6 z-10 relative">
        <div ref={patientReportRef}>
          {processedTestData.map((item, index) => (
            <div
              key={index}
              className="bg-[#C7CEE5] justify-center items-center  flex min-h-fit mb-2 relative"
              style={{ breakAfter: "page" }}
            >
              {/* Report Page PDF Viewer */}
              <div className="bg-white z-10 printable relative h-fit  mb-1">
                <PatientReport
                  patientDetails={patientDetails}
                  testResults={item} // Passing dynamic test results
                />
              </div>
            </div>
          ))}
        </div>
        <div className="fixed bottom-8 right-8 lg:right-20 z-10">
          {/* Download button */}
          <div className="relative group mb-4">
            ~
            <ReactToPrint
              trigger={() => (
                <img
                  src={download}
                  alt="Download PDF"
                  className="w-10 lg:w-12 cursor-pointer hover:scale-110 transition-transform"
                />
              )}
              content={() => patientReportRef.current}
              documentTitle={`${patientDetails.Name}_${patientDetails.PatientID}`}
            />
            <span className="absolute right-14 top-1/2 -translate-y-1/2 bg-[#845BB3] text-white text-xs rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity">
              Download
            </span>
          </div>

          {/* Share button */}
          {/* <div className="relative group mb-4">
                <img
                  src={share}
                  alt="Share PDF"
                  className="w-10 lg:w-12 cursor-pointer hover:scale-110 transition-transform"
                  onClick={handleShare}
                />
                <span className="absolute right-14 top-1/2 -translate-y-1/2 bg-[#845BB3] text-white text-xs rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity">
                  {" "}
                  Share
                </span>
              </div> */}

          {/* WhatsApp button */}
          <div className="relative group mb-4">
            <img
              src={whatsapp}
              alt="Upload PDF"
              className="w-10 lg:w-12 cursor-pointer hover:scale-110 transition-transform"
              onClick={handlePdfUpload}
            />
            <span className="absolute right-14 top-1/2 -translate-y-1/2 bg-[#845BB3] text-white text-xs rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity">
              {" "}
              WhatsApp
            </span>
          </div>

          {/* Help Icon */}
          <div className="relative group">
            <Link to="../faq">
              <img
                src={help}
                alt="Help icon"
                className="w-10 lg:w-12 hover:scale-110 transition-transform"
              />
            </Link>
            <span className="absolute right-14 top-1/2 -translate-y-1/2 bg-[#845BB3] text-white text-xs rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity">
              {" "}
              FAQs
            </span>
          </div>
        </div>
        {loading && (
          <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-800 bg-opacity-50 z-50">
            <div className="w-10 h-10 border-4 border-t-red-500 border-gray-300 rounded-full animate-spin"></div>
            <div className="mt-4 text-white border-t-red-400 text-lg font-semibold">
              It May Take Upto 2 Minutes
            </div>
          </div>
        )}
      </div>

      {/* CSS Styles */}
      <style jsx>{`
        @media print {
          body * {
            visibility: hidden;
          }
          .printable,
          .printable * {
            visibility: visible;
          }
          .printable {
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      `}</style>
    </>
  );
}
