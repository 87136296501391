import React, { useState } from "react";
import CrossIcon from "../../assets/icons/cross.svg";
import Stethoscope from "../../assets/stethoscope.jpeg";
import { useSelector } from "react-redux";

const StethoscopeModal = ({ isOpen, onClose }) => {
  const [duration, setDuration] = useState(10); // Default duration is 10 seconds
  const [amplification, setAmplification] = useState(8); // Default amplification is 8
  const [filter, setFilter] = useState("nofilter"); // Default filter is No-filter
  const [loading, setLoading] = useState(false);
  const [showNextModal, setShowNextModal] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [formData, setFormData] = useState({
    webReport: null,
    signalType: null,
  });

  const patientId = useSelector(
    (state) => state.patientSlice.patientData.PatientID
  );

  const handleStartAction = async () => {
    setLoading(true);

    const filename = `${Date.now()}-${patientId}.wav`;
    const data = {
      name: filename,
      duration: duration.toString(),
      record: "true",
      amplification: amplification.toString(),
      filter: filter.toLowerCase(),
    };

    try {
      const response = await fetch("http://localhost:8000/record_playback", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      alert(result.message);

      // Show the loader and transition to the next modal
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to initiate record and playback.");
      setShowNextModal(true);
      setLoaderVisible(true);

      // Start the loader for the specified duration
      setTimeout(async () => {
        setLoaderVisible(false); // Hide loader
        setShowNextModal(false); // Close next modal

        // Update formData with filename and filter
        const updatedFormData = {
          ...formData,
          webReport: filename,
          signalType: filter,
        };
        setFormData(updatedFormData);

        // Call the submission API
        await handleAudioSubmission(updatedFormData);

        // Close the modal
        onClose();
      }, duration * 1000);
    } finally {
      setLoading(false);
    }
  };

  const handleAudioSubmission = async (formData) => {
    setLoading(true);
    try {
      const response = await fetch("http://localhost:8000/report", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      alert(result.message);
      showNextModal(false);
      onclose();
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to submit audio data.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Main Modal */}
      {isOpen && !showNextModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          {loading && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
              <div className="w-10 h-10 border-4 border-t-[#845BB3] border-gray-300 rounded-full animate-spin"></div>
            </div>
          )}
          <div className="bg-[#845BB3] rounded-lg shadow-lg w-[30%] h-[50%] p-6 relative">
            {/* Close Button */}
            <button
              onClick={onClose}
              className="absolute top-3 right-3 text-white hover:text-gray-200 text-xl font-bold"
            >
              <img src={CrossIcon} alt="Close" />
            </button>
            <div>
              <h2 className="text-2xl font-semibold text-center text-white mb-6">
                Stethoscope Modal
              </h2>
              <div className="space-y-6 top-5">
                {/* Duration */}
                <div>
                  <label className="block font-medium text-white mb-2">
                    Duration (seconds)
                  </label>
                  <input
                    type="number"
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                    className="w-full border border-gray-300 rounded-md p-2"
                    min="1"
                    max="30"
                  />
                </div>

                {/* Amplification */}
                <div>
                  <label className="block font-medium text-white mb-2">
                    Amplification
                  </label>
                  <div className="flex items-center space-x-4">
                    <input
                      type="range"
                      value={amplification}
                      onChange={(e) => setAmplification(e.target.value)}
                      min="2"
                      max="25"
                      className="flex-grow appearance-none h-2 bg-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-white transition"
                    />
                    <span className="text-center text-white font-semibold">
                      {amplification}
                    </span>
                  </div>
                </div>

                {/* Filter Type */}
                <div>
                  <label className="block font-medium text-white mb-2">
                    Filter Type
                  </label>
                  <div className="flex space-x-4">
                    {"nofilter heart lungs".split(" ").map((type) => (
                      <label
                        key={type}
                        className={`flex items-center justify-center p-2 rounded-lg border transition cursor-pointer ${
                          filter === type
                            ? "bg-[#6A48A0] text-white"
                            : "bg-gray-100 text-gray-700 border-gray-300"
                        } hover:border-gray-400`}
                      >
                        <input
                          type="radio"
                          name="filter"
                          value={type}
                          checked={filter === type}
                          onChange={(e) => setFilter(e.target.value)}
                          className="hidden"
                        />
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Start Action Button */}
            <div className="mt-8 flex justify-center">
              <button
                onClick={handleStartAction}
                className="px-8 py-2 bg-[#6A48A0] text-white font-semibold rounded-md hover:bg-[#56408B] transition"
              >
                Start Action
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Next Modal */}
      {/* Next Modal */}
      {showNextModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          {/* Loader overlay */}
          {loaderVisible && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-60">
              <div className="text-white text-lg">Recording...</div>
            </div>
          )}

          <div
            className={`bg-[#845BB3] rounded-lg shadow-lg w-[30%] h-[60%] p-6 relative ${
              loaderVisible ? "pointer-events-none" : ""
            }`}
          >
            {/* Close Button */}
            <button
              onClick={onClose}
              className="absolute top-3 right-3 text-white hover:text-gray-200 text-xl font-bold"
            >
              <img src={CrossIcon} alt="Close" />
            </button>
            <div className="flex flex-col items-center justify-center h-full">
              {/* Stethoscope Image */}
              <div className="flex mb-3 w-[70%] h-[70%]">
                <img src={Stethoscope} alt="" />
              </div>
              {/* Text */}
              <div className="flex items-center justify-center mb-3">
                <h2 className="text-white text-2xl text-center">
                  Please Place the Stethoscope on the Patient
                </h2>
              </div>
              {/* Circular Loader */}
              {loaderVisible && (
                <div className="flex items-center justify-center mt-4">
                  <div className="w-10 h-10 border-4 border-t-[#6A48A0] border-gray-300 rounded-full animate-spin"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StethoscopeModal;
