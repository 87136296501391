import React, { useEffect } from "react";
import logo from "../assets/superceuticals-logo.png";
import iso27 from "../assets/iso27.png";
import iso13 from "../assets/iso13.png";
import iso14 from "../assets/iso14.png";
import iso15 from "../assets/iso15.png";
import { useSelector } from "react-redux";
import { commentsData } from "../translate/testInstruction";
// import {
//   LipidProfile,
//   HbA1c,
//   BloodPressureSummary,
// } from "../translate/testSummery";
import DynamicRender from "./dynamicRender";
import { samplepct } from "../translate/testSummery";
import TestParameter from "../Pages/protected/TestParameter";

const PatientReport = ({ patientDetails, testResults }) => {
  const refBy = useSelector((state) => state.testSlice.referedBy);

  let testComponent;
  console.log("______testResults", testResults);

  if (testResults?.fields.length > 1) {
    if (testResults?.fields[0].parentField === "Urea") {
      console.log("Patient Report Test Results: 34", testResults);
      const testFieldName = testResults?.fields[0].parentField;

      console.log("67", testFieldName);
      testComponent = (
        <DynamicRender
          componentName={testFieldName?.toLowerCase().replace(/[^a-z0-9]/g, "")}
        />
      );
      console.log("Rendering component:", testComponent);
    } else if (testResults?.fields[0].parentField === "Creatinine") {
      console.log("Patient Report Test Results: 34", testResults);
      const testFieldName = testResults?.fields[0].parentField;

      console.log("67", testFieldName);
      testComponent = (
        <DynamicRender
          componentName={testFieldName?.toLowerCase().replace(/[^a-z0-9]/g, "")}
        />
      );
      console.log("Rendering component:", testComponent);
    } else if (testResults?.fields[0].parentField === "Routine Panel") {
      console.log("Patient Report Test Results: 34", testResults);
      const testFieldName = testResults?.fields[0].parentField;

      console.log("52", testFieldName);
      testComponent = (
        <DynamicRender
          componentName={testFieldName?.toLowerCase().replace(/[^a-z0-9]/g, "")}
        />
      );
      console.log("Rendering component:", testComponent);
    } else if (
      testResults?.fields[0].parentField === "Urine Creatinine + Microalbumin"
    ) {
      console.log("36///", testResults);
      const testFieldName = testResults?.fields[0].parentField;

      console.log("67", testFieldName);
      testComponent = (
        <DynamicRender
          componentName={testFieldName?.toLowerCase().replace(/[^a-z0-9]/g, "")}
        />
      );
      console.log("45 Rendering component:", testComponent);
    } else if (
      testResults?.fields[0].parentField === "Complete Renal Profile"
    ) {
      console.log("36///", testResults);
      const testFieldName = testResults?.fields[0].parentField;

      console.log("67", testFieldName);
      testComponent = (
        <DynamicRender
          componentName={testFieldName?.toLowerCase().replace(/[^a-z0-9]/g, "")}
        />
      );
      console.log("45 Rendering component:", testComponent);
    } else if (testResults?.fields[0].fieldName === "Height") {
      console.log("36///", testResults);
      const testFieldName = testResults?.fields[0].parentField;

      console.log("671", testFieldName);
      testComponent = (
        <DynamicRender
          componentName={testFieldName?.toLowerCase().replace(/[^a-z0-9]/g, "")}
        />
      );
      console.log("45 Rendering component:", testComponent);
    } else {
      console.log("Patient Report Test Results:", testResults);
      const testFieldName = testResults?.testFieldName;

      console.log("67", testFieldName);
      testComponent = (
        <DynamicRender
          componentName={testFieldName?.toLowerCase().replace(/[^a-z0-9]/g, "")}
        />
      );
      console.log("Rendering component:", testComponent);
    }
  } else if (testResults?.fields.length > 1) {
    const parentField = testResults.fields[0]?.parentField;
    console.log("48", parentField);

    if (parentField) {
      console.log("Patient Report Test Results:", testResults);
      const testFieldName = testResults?.fields[0]?.parentField;

      console.log("90", testFieldName);
      testComponent = (
        <DynamicRender
          componentName={testFieldName?.toLowerCase().replace(/[^a-z0-9]/g, "")}
        />
      );
      console.log("Rendering component:", testFieldName);
    } else {
      console.log("Patient Report Test Results:", testResults);
      const testFieldName = testResults?.testFieldName;

      console.log("67", testFieldName);
      testComponent = (
        <DynamicRender
          componentName={testFieldName?.toLowerCase().replace(/[^a-z0-9]/g, "")}
        />
      );
      console.log("Rendering component:", testComponent);
    }
  } else {
    const testFieldName = testResults?.fields[0].fieldName
      ?.toLowerCase()
      .replace(/[^a-z0-9]/g, "");
    console.log("Hello");
    console.log("67", testFieldName);
    testComponent = (
      <DynamicRender
        componentName={testFieldName?.toLowerCase().replace(/[^a-z0-9]/g, "")}
      />
    );
  }

  const valueChecker = (value, rangeOfValue) => {
    console.log(rangeOfValue);
    const result = testResults?.testFieldName;
    console.log(result);
    if (result === "AI Analysis") {
      return true;
    } else if (typeof rangeOfValue === "string") {
      if (rangeOfValue.startsWith("<")) {
        // Extract number and check if value is less than the number
        const limit = parseFloat(rangeOfValue.match(/[-+]?\d*\.?\d+/)[0]);
        console.log(limit, "$$$$$$");
        return value < limit;
      } else if (rangeOfValue.startsWith(">")) {
        // Extract number and check if value is greater than the number
        const limit = parseFloat(rangeOfValue.match(/[-+]?\d*\.?\d+/)[0]);
        console.log(limit, "$$$$$$");
        return value > limit;
      } else if (rangeOfValue.includes("-")) {
        // Split the range and check if value is within the range
        const [start, end] = rangeOfValue.split("-").map(parseFloat);
        console.log(start, "$$$$$", end);
        return value >= start && value <= end;
      } else if (!rangeOfValue) {
        return value;
      } else if (rangeOfValue === "Positive") {
        return value === "Positive" ? value : false;
      } else if (rangeOfValue === "Negative") {
        return value === "Negative" ? value : false;
      } else if (rangeOfValue === "Non-Reactive") {
        return value === "Non-Reactive" ? value : false;
      } else if (rangeOfValue === "Reactive") {
        return value === "Reactive" ? value : false;
      }
    } else if (typeof rangeOfValue === "object") {
      rangeOfValue =
        patientDetails?.Gender == "Male"
          ? rangeOfValue.Male
          : rangeOfValue.Female;
      console.log(rangeOfValue);
      if (rangeOfValue.startsWith("<")) {
        // Extract number and check if value is less than the number
        const limit = parseFloat(rangeOfValue.match(/[-+]?\d*\.?\d+/)[0]);
        console.log(limit, "$$$$$$");
        return value < limit;
      } else if (rangeOfValue.startsWith(">")) {
        // Extract number and check if value is greater than the number
        const limit = parseFloat(rangeOfValue.match(/[-+]?\d*\.?\d+/)[0]);
        console.log(limit, "$$$$$$");
        return value > limit;
      } else if (rangeOfValue.includes("-")) {
        // Split the range and check if value is within the range
        const [start, end] = rangeOfValue.split("-").map(parseFloat);
        console.log(start, "$$$$$", end);
        return value >= start && value <= end;
      }
    }
  };

  function checkBioRef(bioRef, gender) {
    if (typeof bioRef === "string") {
      return bioRef;
    } else if (typeof bioRef === "object") {
      return gender.toLowerCase() === "male" ? bioRef.Male : bioRef.Female;
    } else {
      return "N/A";
    }
  }

  function removeNumericWords(inputString) {
    console.log("____inputString:", inputString);
    // Check if the inputString is a valid string
    if (typeof inputString !== "string") {
      console.warn("Invalid input passed to removeNumericWords:", inputString);
      return ""; // Return an empty string or handle this case appropriately
    }

    // Proceed with the original logic
    return inputString.replace(/[^a-zA-Z/%]+/g, " ").trim();
  }

  const getFieldDisplayValue = (field) => {
    if (
      field.fieldName === "Estimated GFR" ||
      field.fieldName === "Granulocytes #" ||
      field.fieldName === "Mid Cell #" ||
      field.fieldName === "Lymphocyte #" ||
      field.fieldName === "Platelet Large Cell Count" ||
      field.fieldName === "Platelet Count" ||
      field.fieldName === "Total Leucocyte Count"
    ) {
      return field.unitAndRange;
    } else {
      return removeNumericWords(field?.unitAndRange);
    }
  };

  return (
    <div className="max-w-screen-lg mx-auto p-8 bg-white h-[37.2cm] w-[25cm]">
      {/* Header Section */}
      <div className="border p-4">
        <div className="flex justify-between items-start">
          <div>
            <img src={logo} alt="Logo" className="w-96 h-20 my-2" />
            <p className="text-sm">
              29, Infocity 2, Sector 33, Gurgaon, Haryana - 122005
            </p>
            <p className="text-sm">Gurgaon, Haryana 122022</p>
          </div>
          <div className="flex space-x-6 my-2">
            <img src={iso27} alt="ISO Icon" className="w-16 h-16" />
            <img src={iso13} alt="ISO Icon" className="w-16 h-16" />
            <img src={iso14} alt="ISO Icon" className="w-16 h-16" />
            <img src={iso15} alt="ISO Icon" className="w-16 h-16" />
          </div>
        </div>

        {/* Patient Information */}
        <div className="mt-4 grid grid-cols-3 gap-4 text-sm">
          <div>
            <p>
              <strong>Name:</strong> {patientDetails?.Name || ""}
            </p>
            <p>
              <strong>Place:</strong> {patientDetails?.Place || ""}
            </p>
            <p>
              <strong>Lab No:</strong> {patientDetails?.LabNo || ""}
            </p>
          </div>
          <div>
            <p>
              <strong>Patient ID:</strong> {patientDetails?.PatientID || ""}
            </p>
            <p>
              <strong>Age:</strong> {patientDetails?.Age || ""}
            </p>
            <p>
              <strong>Gender:</strong> {patientDetails?.Gender || ""}
            </p>
          </div>
          <div>
            <p>
              <strong>Ref By:</strong> {refBy || ""}
            </p>
            <p>
              {/* <strong>Date & Time:</strong> {patientDetails?.dateTime || ""} */}
              <strong>Date & Time:</strong>{" "}
              {patientDetails?.dateTime ||
                (() => {
                  const now = new Date();

                  // Format date
                  const day = String(now.getDate()).padStart(2, "0");
                  const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-based
                  const year = now.getFullYear();

                  // Format time
                  const hours = String(now.getHours()).padStart(2, "0");
                  const minutes = String(now.getMinutes()).padStart(2, "0");

                  // Combine date and time
                  return `${day}/${month}/${year} ${hours}:${minutes}`;
                })()}
            </p>
          </div>
        </div>
      </div>

      {/* Test Results Section */}
      {[
        "samplelipidprofile",
        "sampleTsh",
        "samplePCT",
        "samplebetahcg",
        "sample2betahcg",
        "samplelhluteinizinghormone",
        "sampletotalt4thyroxine",
        "samplefreet4thyroxine",
        "samplehba1c",
        "samplecompleteliverfunctiontests",
        "sampleurinecreatininemicroalbumin ",
        "sampleRenal",
        "sampleRenal2",
        "samplentprobnp",
        "sampleroutinepanel",
        "samplecreatinine",
        "samplecreatinine",
        "samplesyphillishivcombo",
        "samplebloodpressure",
        "samplegeneral",
        "samplecompletebloodcount",
        "sampleurinealbumin",
        "samplebilirubintotal",
        "Cough Analysis",
        "smapleggtp",
        "samplealp",
        "samplebilirubindirect",
        "sampletotalprotein",
        "samplealbumin",
        "samplesgptalt",
        "samplesgoast",
        "samplealbumin",
        "sampleuricacid",
        "sampletotalprotein",
        "samplesodium",
        "samplepotassium",
        "samplehemoglobin",
        "sampletyphoidigmigg",
        "samplecholeraag",
        "samplechikungunyaigmigg",
        "sampledengueduoagab",
        "samplesyphillisantibody",
        "samplemaleriapfpvag",
        "samplemaleriapfpanag",
        "sampleelephantiasisag",
        "samplescrubtyphusigmigg",
        "samplehpyloriantigen",
        "samplehivantibody",
        "sampleleptospiraigmigg",
        "smapleggtp",
        "samplealp",
        "samplebilirubindirect",
        "sampletotalprotein",
        "samplealbumin",
        "samplesgptalt",
        "samplesgoast",
        "sample2lhluteinizinghormone",
        "sampleurea",
        "sampleft4freethyroxine",
      ].includes(testResults?.fields[0]?.fieldName) || ["ENT"].includes(testResults?.fields[0]?.parentFieldName) ? null : (
        <div className="border mt-6 p-2">
          <table className="min-w-full table-auto text-left text-sm">
            <thead>
              <tr className="bg-[#D7D4F8] text-[#845BB3]">
                <th className="px-4 py-2 font-semibold text-sm">
                  Test Category
                </th>
                <th className="px-4 py-2 font-semibold text-sm">Test Name</th>
                <th className="px-4 py-2 font-semibold text-sm">Results</th>
                <th className="px-4 py-2 font-semibold text-sm">Units</th>
                <th className="px-4 py-2 font-semibold text-sm">
                  Bio. Ref. Interval
                </th>
              </tr>
            </thead>
            <tbody>
              {testResults?.fields?.map((field, index) => (
                <tr key={index} className="border-b">
                  <td className="px-4 py-2">{testResults?.testFieldName}</td>
                  <td className="px-4 py-2">{field?.fieldName}</td>
                  <td className="px-4 py-2">
                    {valueChecker(field?.value, field?.bioReferenceInterval) ? (
                      field?.value
                    ) : (
                      <b>{field?.value}*</b>
                    )}
                  </td>
                  <td className="px-4 py-2">
                    {/* {field.fieldName === "Estimated GFR"
                      ? field.unitAndRange
                      : removeNumericWords(field?.unitAndRange)} */}
                    {getFieldDisplayValue(field)}
                  </td>
                  <td className="px-4 py-2">
                    {checkBioRef(
                      field?.bioReferenceInterval,
                      patientDetails?.Gender
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {["ENT"].includes(testResults?.fields[0]?.parentFieldName) ? (
        <div className="border mt-6 p-2">
          <table className="min-w-full table-auto text-left text-sm">
            <thead>
              <tr className="bg-[#D7D4F8] text-[#845BB3]">
                <th className="px-4 py-2 font-semibold text-sm">
                  Test Category
                </th>
                <th className="px-4 py-2 font-semibold text-sm">Image Name</th>
                <th className="px-4 py-2 font-semibold text-sm">Images</th>
                <th className="px-4 py-2 font-semibold text-sm">Units</th>
                <th className="px-4 py-2 font-semibold text-sm">
                  Bio. Ref. Interval
                </th>
              </tr>
            </thead>
            <tbody>
              {testResults?.fields?.map((field, index) => (
                <tr key={index} className="border-b">
                  <td className="px-4 py-2">{field?.parentFieldName}</td>
                  <td className="px-4 py-2">{field?.fieldName}</td>
                  <td className="px-4 py-2">
                    <img
                      src={field?.value}
                      alt={`Captured ${index + 1}`}
                      className="w-[200px] h-[150px] object-cover rounded-md"
                    />
                  </td>
                  <td className="px-4 py-2">
                    {/* {field.fieldName === "Estimated GFR"
                       ? field.unitAndRange
                       : removeNumericWords(field?.unitAndRange)} */}
                    {getFieldDisplayValue(field)}
                  </td>
                  <td className="px-4 py-2">
                    {checkBioRef(
                      field?.bioReferenceInterval,
                      patientDetails?.Gender
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}

      {["Cough Analysis"].includes(testResults?.fields[0]?.fieldName) ? (
        <>
          <TestParameter />
        </>
      ) : null}

      {/* 
      Comments Section
      {testResults.fields.length > 0 &&
        getTestComments(
          testResults?.testFieldName,
          testResults?.fields[0]?.fieldName
        )} */}

      {/* Display Lipid Profile and HbA1c components if applicable */}
      {/* {testResults?.testFieldName === "Lipid Profile" && <LipidProfile />}
      {testResults?.fields?.some((field) => field.fieldName === "HbA1c") && (
        <HbA1c />
      )}
      {testResults?.fields?.some(
        (field) => field.fieldName === "Blood Pressure"
      ) && <BloodPressureSummary />} */}

      {testComponent ? (
        <div className="mt-8 ">{testComponent}</div>
      ) : (
        <div></div> // Empty div as fallback
      )}

      {/* Important Instructions */}
      {[
        "Total Cholesterol",
        "PCT",
        "Beta-HCG",
        "samplebetahcg",
        "LH (Luteinizing Hormone)",
        "Total T4 (Thyroxine)",
        "Free T4 (Thyroxine)",
        "Urine Creatinine",
        "Urea",
        "sampleRenal",
        "Urine Glucose",
        "TSH",
        "NT proBNP",
        "HbA1c",
        "Creatinine",
        "HIV-1 Antibodies",
        "Blood Pressure",
        "Height",
        "Lipid Profile",
        "SGOT/ AST",
        "Urine Albumin",
        "GGTP",
        "Bilirubin Total",
        "Bilirubin Direct",
        "Total Protein",
        "Albumin",
        "SGPT/ ALT",
        "Albumin",
        "Uric Acid",
        "Total Protein",
        "Sodium",
        "Potassium",
        "Hemoglobin",
        "Typhoid IgM",
        "Vibrio cholerae O1 Antigen",
        "Chikungunya IgM",
        "Dengue NS1 Antigen",
        "Plasmodium falciparum HRP-II",
        "Filarial Antigen Detection",
        "Scrub Typhus IgM",
        "H. Pylori Antigen",
        "HIV 1",
        "Leptospira IgM",
        "samplelhluteinizinghormone",
      ].includes(testResults?.fields[0]?.fieldName) ? null : (
        <div className="border mt-6 p-2 absolute bottom-[6em] w-[88.5%]">
          <p className="font-bold text-sm">IMPORTANT INSTRUCTIONS</p>
          <ul className="list-disc list-inside text-sm">
            <li>Test results pertain to specimen submitted.</li>
            <li>
              All test results are dependent on the quality of the sample
              received.
            </li>
            <li>
              Investigations are only a tool to facilitate arriving at a
              diagnosis and should be clinically correlated by the referring
              physician.
            </li>
            <li>
              Report delivery may be delayed due to unforeseen conditions.
            </li>
            <li>
              Certain tests may require further testing at additional costs for
              derivation of exact value.
            </li>
            <li>Test results may show inter/Intra laboratory variations.</li>
            <li>
              The courts of Delhi shall have exclusive jurisdiction in all
              disputes/claims concerning test(s) and/or results of test(s).
            </li>
            <li>Test results are not valid for medico-legal purposes.</li>
          </ul>
        </div>
      )}

      {/* <p className="text-center text-xs absolute bottom-[5em] w-full left-0">
        --------------------End of Test--------------------
      </p> */}

      {/* Footer Section */}
      <div className="text-center bg-purple-200 mt-6 absolute bottom-0 left-0 w-full">
        <p className="text-sm mt-2 italic">
          In case the results are unexpected or alarming, the patient is advised
          to contact the lab immediately for possible remedial action.
        </p>
        <p className="text-xs mt-1">"एक कदम स्वस्थ भारत की ओर"</p>
      </div>
    </div>
  );
};

export default PatientReport;
