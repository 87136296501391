import React, { useRef, useCallback, useState, useEffect } from "react";
import Webcam from "react-webcam";

const MultipleCaptureModal = ({ isOpen, onClose, onCapture }) => {
  const webcamRef = useRef(null);
  const [capturedImages, setCapturedImages] = useState([]);
  const [videoConstraints, setVideoConstraints] = useState({
    facingMode: "user",
  });
  const [devices, setDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);

  // Fetch available video input devices
  useEffect(() => {
    const fetchDevices = async () => {
      const allDevices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = allDevices.filter(
        (device) => device.kind === "videoinput"
      );
      setDevices(videoDevices);
      if (videoDevices.length > 0) {
        setSelectedDeviceId(videoDevices[0].deviceId); // Default to the first device
        setVideoConstraints({ deviceId: videoDevices[0].deviceId });
      }
    };

    fetchDevices();
  }, []);

  // Capture Image from Webcam
  const captureImage = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        setCapturedImages((prevImages) => [...prevImages, imageSrc]);
      } else {
        console.error("Failed to capture image.");
      }
    }
  }, [webcamRef]);

  // Remove a captured image
  const removeImage = (indexToRemove) => {
    setCapturedImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };

  // Handle Submit
  const handleSubmit = () => {
    if (capturedImages.length > 0) {
      onCapture(capturedImages);
      onClose();
    }
  };

  // Handle Camera Selection
  const handleCameraChange = (event) => {
    const deviceId = event.target.value;
    setSelectedDeviceId(deviceId);
    setVideoConstraints({ deviceId });
  };

  if (!isOpen) {
    return null; // Do not render anything if modal is not open
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white overflow-y-scroll max-h-[100%] p-4 rounded-md relative w-full max-w-md">
        <h2 className="text-xl font-bold mb-4 text-center">Capture Images</h2>

        <select
          value={selectedDeviceId || ""}
          onChange={handleCameraChange}
          className="mb-4 p-2 border rounded-md w-full"
        >
          {devices.map((device) => (
            <option key={device.deviceId} value={device.deviceId}>
              {device.label || `Camera ${device.deviceId}`}
            </option>
          ))}
        </select>

        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/png"
          videoConstraints={videoConstraints}
          className="w-full h-auto rounded-md mb-4"
        />

        <div className="flex justify-between mb-4">
          <button
            onClick={captureImage}
            className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-md"
          >
            Capture Image
          </button>
          <button
            onClick={onClose}
            className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-md"
          >
            Close
          </button>
        </div>

        {capturedImages.length > 0 && (
          <>
            <h3 className="text-lg font-semibold mb-2">
              Preview Captured Images
            </h3>
            <div className="flex flex-wrap gap-4 mb-4">
              {capturedImages.map((image, index) => (
                <div key={index} className="w-20 h-20 relative">
                  <img
                    src={image}
                    alt={`Captured ${index + 1}`}
                    className="w-full h-full object-cover rounded-md"
                  />
                  <button
                    onClick={() => removeImage(index)}
                    className="absolute top-0 right-0 bg-red-500 text-white w-5 h-5 rounded-full flex items-center justify-center text-xs"
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
            <button
              onClick={handleSubmit}
              className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded-md w-full"
            >
              Submit ({capturedImages.length} Images)
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default MultipleCaptureModal;
